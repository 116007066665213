@media(min-width:0px) and (max-width:575px){
.container{ width:100%;}

.common-title-hp{ margin-top:40px; }
.common-title-img-hp{ width:60px; }
.common-title-center-hp .common-title-img-hp{ left: -20px; top: -20px; }
.common-title-hp h2{ font-size: 25px; line-height: 30px; }
.common-title-hp h2:after{ bottom: 5px; right: -30px; width:70px; height:72px; }
.common-title-hp p { font-size: 16px; line-height: 32px; }
.common-title-text-hp{ max-width:200px; }

.common-yellow-hp{ font-size: 15px; line-height: 24px; padding: 10px 25px 10px 25px; min-width:290px; }
.common-yellow-hp h2{ font-size: 15px; line-height: 24px; } 
.common-yellow-hp h2 span{ font-size:12px; }
.common-yellow-hp:after{ width:94%; left:3%; }

.common-btn-hp{ min-width:290px; font-size: 14px; line-height: 30px; }

/*--------------- HEADER_START --------------*/
#header{ padding:0px;}
.header-in-hp{ padding:0 15px; height:70px; justify-content: space-between; }
.logo-hp{ width: 160px; }
.logo-hp img{ width:100%; height:auto; }
.mobile-menu-icon-hp{ display:block; margin-top:0px;  }
.menu-toggle-btn{  margin:18px auto;  }
.navigation { padding: 15px; position: absolute; left: 0; width: 100%; background-color: #000; display: none; top: 70px; clear: both; }
.navigation > ul { text-align: center; width: 100%;  padding: 0;  }
.navigation > ul > li { display: block; padding: 0 0 15px 0; font-size:17px; line-height:23px; }
.navigation > ul > li > a{ color:#fff; }
.navigation > ul > li.contact-hp{ border:0px; margin:auto; height: auto; }
.navigation > ul > li.contact-hp > a{ padding:10px 0; }
.navigation > ul > li.contact-hp:after{ border-top: 66px solid #0F5FBE; border-right: calc(100vw - 30px) solid transparent; }
/*--------------- HEADER_END --------------*/

/*--------------- CONTAIN_START --------------*/
#contain{ margin:0px; padding:70px 0 0 0;}

.contact-block-in-hp{ height:200px; }
.contact-title-hp { font-size: 23px; line-height: 26px; }
.contact-btn-hp{ padding:25px 0 0 0; }

.phone-text-hp{ display:block; font-size:16px; }
.phone-number-hp{ font-size: 30px; line-height: 40px; padding:5px 0 0 0; display:block; }
.phone-time-hp{ margin-top:15px; }

.recruit-middle-hp{ margin:20px 0 0 0;  }
.recruit-box-hp{ width:100%; margin-right:0px; margin-bottom:20px; } 
.recruit-box-overlay-hp{ padding:15px; }
.recruit-box-in-hp{ width:100%; margin:0; padding:40px; min-height:inherit; }
.recruit-title-hp{ font-size: 20px; line-height: 20px; }
.recruit-desc-hp{ font-size: 14px; line-height: 26px; padding:20px 0 0 0; }
.recruit-box-hp:nth-child(2n) .recruit-box-in-hp{ margin:0px; }
.recruit-btn-hp{ padding:20px 0 40px 0; }
/*--------------- CONTAIN_END --------------*/

/*--------------- FOOTER_START --------------*/
.footer-left-hp{ width:100%; }
.footer-logo-hp{ width:240px; padding-bottom:20px; }
.footer-address-hp{ font-size:12px; }
.footer-right-hp{ width:100%; padding-top:15px; }
.footer-link-hp{ text-align:left; }
.footer-link-hp ul li{ font-size: 13px; line-height: 14px; }
.footer-bottom-left-hp{ width:100%; text-align:center; padding-bottom:10px; }
.footer-bottom-right-hp{ width:100%; text-align:center; }
.back-to-top-hp{ bottom:10vw; }
/*--------------- FOOTER_END --------------*/

/*--------------- SERVICE_START --------------*/
.manufacturer-block-sp{ padding:40px 0; }
.manufacturer-block-sp:after{ width:100px; }
.manufacturer-block-sp:before{ width:120px; }
.manufacturer-desc-hp{ padding:30px 0 0 0; font-size: 13px; line-height: 26px; }

.banner-block-overlay-sp{ background-position:right -130px top; }
.banner-middle-sp h1{ font-size:30px; line-height:30px; }
.banner-middle-sp p{ font-size:18px; line-height:25px; }

.common-box-title-sp { font-size: 16px; line-height: 40px; }
.common-box-title-sp h3{ font-size: 16px; line-height: 40px; }
.common-box-desc-sp { padding: 20px 0 0 0; font-size: 13px; line-height: 24px; }

.table-data-list-cp{ display:block; }
.table-data-left-cp{ width:100%; display:block; padding:6px 10px; }
.table-data-right-cp{ width:100%; display:block; padding:10px; font-size:14px; line-height:26px; }

.interview-box-rp{ width:100%; padding:0 0 30px 0; }
/*--------------- SERVICE_END --------------*/

/*--------------- CONTACT_START --------------*/
.form-flow-cp{ padding-bottom:30px; }
.form-flow-cp ul li{ font-size:13px; line-height: 28px;  padding: 0 21px; }
.form-flow-cp ul li:after{ width:24px; right:-14px; }
.form-field-cp{ padding-bottom:10px; }
.form-field-label-cp{  width:100%; padding:0px; padding-bottom:5px; font-size:14px; }
.form-field-checkbox-cp{ width:100%; padding:0px; }
.formcheck{ font-size:14px; margin-bottom:10px; }
.form-field-input-cp{ width:100%; }
.form-field-input-cp textarea{ height:150px; }
.form-term-cp{ font-size:14px; }
/*--------------- CONTACT_END --------------*/

}

@media(min-width:576px) and (max-width:767px){
.container{ width:100%;}

.common-title-hp{ margin-top:40px; }
.common-title-img-hp{ width:80px; }
.common-title-center-hp .common-title-img-hp{ left: -60px; top: -30px; }
.common-title-hp h2{ font-size: 35px; line-height: 40px; }
.common-title-hp h2:after{ bottom: 5px; right: -30px; width:70px; height:72px; }
.common-title-hp p { font-size: 16px; line-height: 32px; }

.common-yellow-hp{ font-size: 15px; line-height: 24px; padding: 10px 25px 10px 25px; min-width:546px; }
.common-yellow-hp h2{ font-size: 15px; line-height: 24px; } 
.common-yellow-hp h2 span{ font-size:12px; }
.common-yellow-hp:after{ width:94%; left:3%; }

.common-btn-hp{ min-width:290px; font-size: 14px; line-height: 30px; }

/*--------------- HEADER_START --------------*/
#header{ padding:0px;}
.header-in-hp{ padding:0 15px; height:70px; justify-content: space-between; }
.logo-hp{ width: 160px; }
.logo-hp img{ width:100%; height:auto; }
.mobile-menu-icon-hp{ display:block; margin-top:0px;  }
.menu-toggle-btn{  margin:18px auto;  }
.navigation { padding: 15px; position: absolute; left: 0; width: 100%; background-color: #000; display: none; top: 70px; clear: both; }
.navigation > ul { text-align: center; width: 100%;  padding: 0;  }
.navigation > ul > li { display: block; padding: 0 0 15px 0; font-size:17px; line-height:23px; }
.navigation > ul > li > a{ color:#fff; }
.navigation > ul > li.contact-hp{ border:0px; margin:auto; height: auto; }
.navigation > ul > li.contact-hp > a{ padding:10px 0; }
.navigation > ul > li.contact-hp:after{ border-top: 66px solid #0F5FBE; border-right: calc(100vw - 30px) solid transparent; }
/*--------------- HEADER_END --------------*/

/*--------------- CONTAIN_START --------------*/
#contain{ margin:0px; padding:70px 0 0 0;}

.contact-block-in-hp{ height:200px; }
.contact-title-hp { font-size: 23px; line-height: 26px; }
.contact-btn-hp{ padding:25px 0 0 0; }

.phone-text-hp{ display:block; font-size:16px; }
.phone-number-hp{ font-size: 30px; line-height: 40px; padding:5px 0 0 0; display:block; }
.phone-time-hp{ margin-top:15px; }

.recruit-middle-hp{ margin:20px 0 0 0;  }
.recruit-box-overlay-hp{ padding:15px; }
.recruit-box-in-hp{ width:100%; margin:0; padding:40px; min-height:inherit; }
.recruit-title-hp{ font-size: 20px; line-height: 20px; }
.recruit-desc-hp{ font-size: 14px; line-height: 26px; padding:20px 0 0 0; }
.recruit-box-hp:nth-child(2n) .recruit-box-in-hp{ margin:0px; }
.recruit-btn-hp{ padding:40px 0 40px 0; }
/*--------------- CONTAIN_END --------------*/

/*--------------- FOOTER_START --------------*/
.footer-left-hp{ width:50%; }
.footer-logo-hp{ width:240px; padding-bottom:20px; }
.footer-address-hp{ font-size:12px; }
.footer-right-hp{ width:48%; padding-top:15px; }
.footer-link-hp{ text-align:left; }
.footer-link-hp ul li{ font-size: 12px; line-height: 14px; }
/*--------------- FOOTER_END --------------*/

/*--------------- SERVICE_START --------------*/
.manufacturer-block-sp{ padding:40px 0; }
.manufacturer-block-sp:after{ width:100px; }
.manufacturer-block-sp:before{ width:120px; }
.manufacturer-desc-hp{ padding:30px 0 0 0; font-size: 13px; line-height: 26px; }

.banner-middle-sp h1{ font-size:40px; line-height:40px; }
.banner-middle-sp p{ font-size:20px; line-height:25px; }

.common-box-title-sp { font-size: 16px; line-height: 30px; }
.common-box-title-sp h3{ font-size: 16px; line-height: 30px; }
.common-box-title-sp h3 br{ display:none; }
.common-box-desc-sp { padding: 20px 0 0 0; font-size: 13px; line-height: 24px; }

.table-data-left-cp{ padding:6px 10px; }
.table-data-right-cp{ padding:10px; font-size:14px; line-height:26px; }
.interview-box-rp{  padding:0 20px; }
/*--------------- SERVICE_END --------------*/

/*--------------- CONTACT_START --------------*/
.form-flow-cp{ padding-bottom:30px; }
.form-field-cp{ padding-bottom:10px; }
.form-field-label-cp{ width:170px; padding:0px; padding-bottom:5px; font-size:14px; }
.form-field-checkbox-cp{ width:calc(100% - 170px); }
.formcheck{ font-size:14px; margin-bottom:10px; }
.form-field-input-cp{ width:calc(100% - 170px); }
.form-field-input-cp textarea{ height:150px; }
.form-term-cp{ font-size:14px; }
/*--------------- CONTACT_END --------------*/
}

@media(min-width:768px) and (max-width:991px){
.container{ width:768px;}

.common-title-hp{ margin-top:40px; }
.common-title-img-hp{ width:80px; }
.common-title-center-hp .common-title-img-hp{ left: -60px; top: -30px; }
.common-title-hp h2{ font-size: 35px; line-height: 40px; }
.common-title-hp h2:after{ bottom: 5px; right: -30px; width:70px; height:72px; }
.common-title-hp p { font-size: 16px; line-height: 32px; }

.common-yellow-hp{ font-size: 15px; line-height: 24px; padding: 10px 25px 10px 25px; }
.common-yellow-hp h2{ font-size: 15px; line-height: 24px; } 
.common-yellow-hp h2 span{ font-size:12px; }
.common-yellow-hp:after{ width:94%; left:3%; }

.common-btn-hp{ min-width:290px; font-size: 14px; line-height: 30px; }

/*--------------- HEADER_START --------------*/
#header{ padding:0px;}
.header-in-hp{ padding:0 15px; height:70px; justify-content: space-between; }
.logo-hp{ width: 160px; }
.logo-hp img{ width:100%; height:auto; }
.mobile-menu-icon-hp{ display:block; margin-top:0px;  }
.menu-toggle-btn{  margin:18px auto;  }
.navigation { padding: 15px; position: absolute; left: 0; width: 100%; background-color: #000; display: none; top: 70px; clear: both; }
.navigation > ul { text-align: center; width: 100%;  padding: 0;  }
.navigation > ul > li { display: block; padding: 0 0 15px 0; font-size:17px; line-height:23px; }
.navigation > ul > li > a{ color:#fff; }
.navigation > ul > li.contact-hp{ border:0px; margin:auto; height: auto; }
.navigation > ul > li.contact-hp > a{ padding:10px 0; }
.navigation > ul > li.contact-hp:after{ border-top: 66px solid #0F5FBE; border-right: calc(100vw - 30px) solid transparent; }
/*--------------- HEADER_END --------------*/

/*--------------- CONTAIN_START --------------*/
#contain{ margin:0px; padding:70px 0 0 0;}

.contact-block-in-hp{ height:200px; }
.contact-title-hp { font-size: 23px; line-height: 26px; }
.contact-btn-hp{ padding:25px 0 0 0; }

.phone-text-hp{ font-size:16px; }
.phone-number-hp{ font-size: 30px; line-height: 40px; padding:0 30px; }

.recruit-middle-hp{ margin:20px 0 0 0;  }
.recruit-box-overlay-hp{ padding:15px; }
.recruit-box-in-hp{ width:100%; margin:0; padding:40px; min-height:inherit; }
.recruit-title-hp{ font-size: 20px; line-height: 20px; }
.recruit-desc-hp{ font-size: 14px; line-height: 26px; padding:20px 0 0 0; }
.recruit-box-hp:nth-child(2n) .recruit-box-in-hp{ margin:0px; }
.recruit-btn-hp{ padding:40px 0 40px 0; }
/*--------------- CONTAIN_END --------------*/

/*--------------- FOOTER_START --------------*/
.footer-left-hp{ width:50%; }
.footer-logo-hp{ width:240px; padding-bottom:20px; }
.footer-address-hp{ font-size:12px; }
.footer-right-hp{ width:48%; padding-top:15px; }
.footer-link-hp{ text-align:left; }
.footer-link-hp ul li{ font-size: 12px; line-height: 14px; }
/*--------------- FOOTER_END --------------*/

/*--------------- SERVICE_START --------------*/
.manufacturer-block-sp{ padding:40px 0; } 
.manufacturer-desc-hp{ padding:30px 30px 0 30px; font-size:16px; line-height:35px; }
.manufacturer-block-sp:before{ width:140px; }
.manufacturer-block-sp:after{ width:130px; }

.banner-middle-sp h1{ font-size:40px; line-height:40px; }
.banner-middle-sp p{ font-size:20px; line-height:25px; }

.common-box-title-sp { font-size: 16px; line-height: 30px; }
.common-box-title-sp h3{ font-size: 16px; line-height: 30px; }
.common-box-desc-sp { padding: 20px 0 0 0; font-size: 13px; line-height: 24px; }
.interview-box-rp{  padding:0 20px; }
/*--------------- SERVICE_END --------------*/
}

@media(min-width:992px) and (max-width:1150px){
.container{ width:992px;}

.common-title-hp{ margin-top:40px; }
.common-title-img-hp{ width:100px; }
.common-title-center-hp .common-title-img-hp{ left: -60px; top: -30px; }
.common-title-hp h2{ font-size: 55px; line-height: 70px; }
.common-title-hp h2:after{ bottom: 8px; right: -50px; width: 100px; height: 92px; }
.common-title-hp p { font-size: 26px; line-height: 42px; }

.common-yellow-hp{ font-size: 20px; line-height: 28px; }
.common-yellow-hp h2{ font-size: 20px; line-height: 28px; } 
.common-yellow-hp:after{ width:94%; left:3%; }

/*--------------- HEADER_START --------------*/
.logo-hp{ width:190px; }
.navigation > ul > li{ font-size: 14px; padding:0 13px; }
.navigation > ul > li span{ font-size:14px; line-height:14px; }
/*--------------- HEADER_END --------------*/

/*--------------- CONTAIN_START --------------*/
.recruit-box-in-hp{ width:416px; margin-right:48px; }
.recruit-box-hp:nth-child(2n) .recruit-box-in-hp{ margin-left:48px; margin-right:0px; }
/*--------------- CONTAIN_END --------------*/

/*--------------- SERVICE_START --------------*/
.manufacturer-desc-hp{ font-size:18px; line-height:36px; }

.common-box-title-sp { font-size: 26px; line-height: 50px; }
.common-box-title-sp h3{ font-size: 26px; line-height: 50px; }
.common-box-desc-sp{ font-size: 14px; line-height: 28px; }
/*--------------- SERVICE_END --------------*/
}

@media(min-width:1151px) and (max-width:1400px){
/*--------------- HEADER_START --------------*/
.logo-hp{ width:190px; }
.navigation > ul > li{ font-size: 14px;  }
.navigation > ul > li span{ font-size:14px; line-height:14px; }
/*--------------- HEADER_END --------------*/
}

@media(min-width:992px) and (max-width:9999px){
.navigation{ display:flex !important; }
}